import type { ProcedureOptions } from '@trpc/server'

import { logError } from '@/shared/utils/error'
import { NetworkError } from '@/shared/utils/errorClasses'

type FetchDataWithTimeoutParams<TInput, TResult> = {
  timeout: number
  trpcResolver: (input: TInput, opts?: ProcedureOptions) => Promise<TResult>
  input?: TInput
  options?: ProcedureOptions
  maxRetries?: number
  retryDelay?: number
}

export const fetchDataWithTimeout = async <TInput, TResult>({
  timeout,
  trpcResolver,
  input,
  options = {},
  maxRetries = 0,
  retryDelay = 1000,
}: FetchDataWithTimeoutParams<TInput, TResult>): Promise<TResult | undefined> => {
  let attempts = 0

  while (attempts <= maxRetries) {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), timeout)

    try {
      return await trpcResolver(input as TInput, {
        ...options,
        signal: controller.signal,
      })
    } catch (error) {
      if (attempts >= maxRetries) {
        throw logError(new NetworkError(error, undefined))
      }
      const delay = retryDelay * Math.pow(2, attempts) // Exponential backoff
      await new Promise((resolve) => setTimeout(resolve, delay))
    } finally {
      clearTimeout(timeoutId)
    }

    attempts++
  }
}
