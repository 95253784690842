'use client'

// eslint-disable-next-line no-restricted-imports
import { usePathname } from 'next/navigation'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { z } from 'zod'

import { getPathname } from '@/i18n/navigation'
import { ServiceAnnouncementList } from '@/shared/components/ServiceAnnouncementList/ServiceAnnouncementList'
import {
  isTypeServiceAnnouncementLoggedIn,
  isTypeServiceAnnouncementOpenWeb,
} from '@/shared/contentful/types/contentTypeGuards'
import { isLocale, useLocale } from '@/shared/locale'
import type { LoggedInRoute } from '@/shared/routes'
import { LOGGED_IN_ROOT_ROUTE } from '@/shared/routes'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'
import { getLocalStorageValue, setLocalStorageValue } from '@/shared/utils/localStorage'

import type {
  AnyServiceAnnouncement,
  ServiceAnnouncementWithPageUrl,
} from './serviceAnnouncementUtils'
import { getFullPathForOpenWebPages } from './serviceAnnouncementUtils'

const getKeyForAnnouncement = (id: string) => `gw-li.announcement-${id}`

const groupByPageUrl = (announcementWithPageUrls: ServiceAnnouncementWithPageUrl[]) => {
  return announcementWithPageUrls.reduce(
    (acc, curr) => {
      const page = curr.pageUrl
      acc[page] = [...(acc[page] ?? []), curr]
      return acc
    },
    {} as Record<string, ServiceAnnouncementWithPageUrl[]>,
  )
}

const createAnnouncementWithPageUrl = (announcement: AnyServiceAnnouncement, page: string) => {
  return {
    name: announcement.name,
    title: announcement.title,
    content: announcement.content,
    type: announcement.type,
    hideCloseButton: announcement.hideCloseButton,
    pageUrl: page || '',
    id: announcement.sys.id,
  } satisfies ServiceAnnouncementWithPageUrl
}

type ServiceAnnouncementProps = {
  serviceAnnouncements: AnyServiceAnnouncement[]
}

export const ServiceAnnouncementNotification: FC<ServiceAnnouncementProps> = ({
  serviceAnnouncements,
}) => {
  const pathname = usePathname()
  const cleanPathname = isLocale(pathname.split('/').find(Boolean) ?? '')
    ? pathname?.replace(/^\/[a-z]{2}/, '')
    : pathname
  const locale = useLocale()
  const [announcements, setAnnouncements] = useState<ServiceAnnouncementWithPageUrl[]>([])

  const announcementWithPagesUrls = useMemo(
    () =>
      serviceAnnouncements.flatMap((announcement) => {
        if (isTypeServiceAnnouncementLoggedIn(announcement)) {
          // get all logged in pages with announcements
          const loggedInPagesAnnouncements = (announcement.loggedInPages ?? [])
            .filter(isNotNullOrUndefined)
            //TODO: Think about updating paths in the contentful to include "/my" prefix
            .map((page) =>
              getPathname({
                locale,
                href: { pathname: `${LOGGED_IN_ROOT_ROUTE}${page}` as LoggedInRoute },
              }),
            )

          return loggedInPagesAnnouncements.map((page) =>
            createAnnouncementWithPageUrl(announcement, page),
          )
        }

        if (isTypeServiceAnnouncementOpenWeb(announcement) && !announcement.global) {
          // get all open web pages with announcements
          const openWebAnnouncements = getFullPathForOpenWebPages(announcement.pagesCollection)
          return openWebAnnouncements.map((page) =>
            createAnnouncementWithPageUrl(announcement, page),
          )
        }

        // return empty if announcement is global
        return []
      }),
    [serviceAnnouncements, locale],
  )

  const globalAnnouncements = useMemo(
    () =>
      serviceAnnouncements.flatMap((announcement) => {
        if (isTypeServiceAnnouncementOpenWeb(announcement) && announcement.global) {
          return {
            name: announcement.name,
            title: announcement.title,
            content: announcement.content,
            type: announcement.type,
            hideCloseButton: announcement.hideCloseButton,
            pageUrl: '*',
            id: announcement.sys.id,
          } satisfies ServiceAnnouncementWithPageUrl
        }

        return []
      }),
    [serviceAnnouncements],
  )

  const groupedByPageUrl = useMemo(() => {
    return groupByPageUrl(announcementWithPagesUrls)
  }, [announcementWithPagesUrls])

  useEffect(() => {
    const announcementsForCurrentPage = groupedByPageUrl[cleanPathname] ?? []

    const allVisibleAnnouncements = [...announcementsForCurrentPage, ...globalAnnouncements]

    if (!allVisibleAnnouncements) {
      return
    }
    setAnnouncements(
      allVisibleAnnouncements.filter((announcement) => {
        return (
          getLocalStorageValue(getKeyForAnnouncement(announcement.id), z.string(), 'false') ===
          'false'
        )
      }),
    )
  }, [groupedByPageUrl, cleanPathname, globalAnnouncements])

  if (serviceAnnouncements.length === 0) {
    return null
  }

  const onClose = (id: ServiceAnnouncementWithPageUrl['id']) => {
    setLocalStorageValue(getKeyForAnnouncement(id), 'true')
    setAnnouncements((announcements) =>
      announcements.filter((announcement) => announcement.id !== id),
    )
  }

  return <ServiceAnnouncementList announcements={announcements} onClose={onClose} />
}
