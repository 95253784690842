'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

import { usePathname, useRouter } from '@/i18n/navigation'
import { routes } from '@/shared/routes'
import { useGlobalStore } from '@/shared/store/provider'

export type QueryParams = 'eurobonus' | 'coop'
export const queryMapping: Record<QueryParams, string> = {
  eurobonus: 'partnerInfo',
  coop: 'coopMembershipId',
}

export const QueryHandler: React.FC = () => {
  const query = useSearchParams()
  const pathname = usePathname()
  const router = useRouter()
  const editableQuery = new URLSearchParams(query)

  const { setCoopMembershipIdCode, setEuroBonusCode } = useGlobalStore(
    (state) => state.partnerFields,
  )

  useEffect(
    () => {
      switch (true) {
        case editableQuery.has(queryMapping.eurobonus): {
          const eurobonusId = editableQuery.get(queryMapping.eurobonus)

          if (typeof eurobonusId === 'string') {
            setEuroBonusCode(eurobonusId)
          }
          editableQuery.delete(queryMapping.eurobonus)

          router.replace(
            {
              pathname: routes.GENERIC,
              params: { slug: pathname },
              query: Object.fromEntries(editableQuery.entries()),
            },
            { scroll: false },
          )
          break
        }
        case editableQuery.has(queryMapping.coop): {
          const coopId = editableQuery.get(queryMapping.coop)

          if (typeof coopId === 'string') {
            setCoopMembershipIdCode(coopId)
          }
          editableQuery.delete(queryMapping.coop)

          router.replace(
            {
              pathname: routes.GENERIC,
              params: { slug: pathname },
              query: Object.fromEntries(editableQuery.entries()),
            },
            { scroll: false },
          )
          break
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  )

  return null
}
