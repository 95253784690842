'use client'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SessionProvider } from 'next-auth/react'
import type { FC, PropsWithChildren } from 'react'

import { TrpcProvider } from '@/shared/providers/trpc'

import { PopupProvider } from './popup'
import { ThemeProvider } from './theme'
import { countryConfig } from '../countryConfig'
import { StoreProvider } from '../store/provider'

export interface IProvidersProps {
  isPreview: boolean
  locale: string
}

export const Providers: FC<PropsWithChildren<IProvidersProps>> = ({ children }) => (
  <ThemeProvider>
    <SessionProvider basePath={countryConfig.basePath + '/api/auth'}>
      <StoreProvider>
        <TrpcProvider>
          <PopupProvider>
            {children}
            {process.env.STORYBOOK ? null : (
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            )}
          </PopupProvider>
        </TrpcProvider>
      </StoreProvider>
    </SessionProvider>
  </ThemeProvider>
)
