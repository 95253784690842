import type { FC, PropsWithChildren } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'

import { ThemeProvider as ElementalThemeProvider } from '@fortum/elemental-ui'

import { generateElementalTheme } from '@/style/theme'

import { browserEnvs } from '../envs'

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  // Dark mode is not officially supported, but it can be tested by setting this env variable
  // For actual support, this should be based on the user system setting or a user preference
  const isDarkMode = browserEnvs.NEXT_PUBLIC_DARK_MODE
  const currentTheme = generateElementalTheme(isDarkMode)

  return (
    <SCThemeProvider theme={currentTheme}>
      <ElementalThemeProvider theme={currentTheme}>{children}</ElementalThemeProvider>
    </SCThemeProvider>
  )
}
