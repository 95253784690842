import type { Locale } from 'next-intl'

import { browserEnvs } from '@/shared/envs'
import { getDefaultLocale, getPathPrefix } from '@/shared/locale'
import type { AppRoute, GeneratedPathnames, StaticRoute } from '@/shared/routes'
import { pathnames } from '@/shared/routes'

export const getLocalizedPathname = (route: keyof GeneratedPathnames, locale: Locale) => {
  const pathname = pathnames[route]
  return typeof pathname === 'string' ? pathname : pathname[locale]
}
/**
 * Method designed to get localized pathname outside of next-intl scope eg. in the playwright tests
 * Shouldn't be used in the app, make sure that you are trying to perform some path operation outside of next intl scope before using.
 */
export const getPathnameRaw = (route: keyof GeneratedPathnames, locale?: Locale) => {
  const country = browserEnvs.NEXT_PUBLIC_COUNTRY
  const defaultLocale = getDefaultLocale(country)
  const path = getLocalizedPathname(route, locale ?? defaultLocale)
  const localePrefix = getPathPrefix(browserEnvs.NEXT_PUBLIC_COUNTRY, locale)

  return `${localePrefix}${path}`
}

export const isStaticRoute = (pathname: unknown): pathname is StaticRoute => {
  return !(pathname as AppRoute).includes('[')
}
