import type { JSX, PropsWithChildren } from 'react'
import { createContext, useMemo, useState } from 'react'

import type { PopupContextType } from '../types/Context'

export const PopupContext = createContext<PopupContextType | undefined>(undefined)
export const PopupProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [shouldShowSmartBanner, setShouldShowSmartBanner] = useState<boolean>(true)

  const popupVal = useMemo(
    () => ({
      shouldShowSmartBanner,
      setShouldShowSmartBanner,
    }),
    [shouldShowSmartBanner],
  )
  return <PopupContext.Provider value={popupVal}>{children}</PopupContext.Provider>
}
