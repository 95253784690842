'use client'

import { useTranslations } from 'next-intl'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import { Button, IconProfileOutline, generateColorLuminances, spacing } from '@fortum/elemental-ui'

import { usePathname } from '@/i18n/navigation'
import { routes } from '@/shared/routes'

export type EnterpriseLoginButtonProps = {
  label?: string
  color?: string
  padding?: string
  height?: string
  loginUrl?: string
}

export const EnterpriseLoginButton = ({
  label,
  color,
  padding,
  height,
  loginUrl,
}: EnterpriseLoginButtonProps) => {
  const t = useTranslations('mainNavigation')
  const pathname = usePathname()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () => {
    if (loginUrl) {
      setIsLoading(true)
      location.assign(loginUrl)
    }
  }

  if (!loginUrl) {
    return null
  }

  return (
    pathname !== routes.SERVICE_BREAK && (
      <StyledButton
        status="plain"
        leftIcon={IconProfileOutline}
        onClick={handleClick}
        color={color}
        aria-label={t('login')}
        fontSize={spacing.xxs}
        ph={padding}
        height={height}
        data-testid="enterprise-login-button"
        loading={isLoading}
      >
        {label}
      </StyledButton>
    )
  )
}

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    &:hover {
      background: none;
      color: ${generateColorLuminances('hover', theme).bg};
    }

    &:active {
      background: none;
      color: ${generateColorLuminances('click', theme).bg};
    }
  `}
`
