'use client'

import { useLayoutEffect, useState } from 'react'

import { getSessionUsername } from '@/shared/components/Navigation/getSessionUsername'
import type { LinkEntry, MainNavigationEntry } from '@/shared/contentful/types'

import { NavigationDesktop } from './NavigationDesktop'
import { NavigationMobile } from './NavigationMobile'
import { SearchModal } from './parts/SearchModal'
import { SkipToMainContent } from './parts/SkipToMainContent'

export type NavigationProps = {
  topNavigation?: LinkEntry[]
  mainNavigation: MainNavigationEntry
  searchUrl: string
}

const defaultTopNavigation: LinkEntry[] = []

/**
 * Component responsible for Application menu presentation
 */
export const Navigation = ({
  mainNavigation,
  topNavigation = defaultTopNavigation,
  searchUrl,
}: NavigationProps) => {
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [username, setUsername] = useState('')

  //NOTE: using useEffect didn't invoke the getSessionUsername serveraction at all in incognito mode and some other cases
  useLayoutEffect(() => {
    void getSessionUsername().then((name) => {
      setUsername(name ?? '')
    })
  }, [])

  return (
    <header style={{ display: 'contents' }}>
      <SkipToMainContent />
      <NavigationDesktop
        topNavigation={topNavigation}
        mainNavigation={mainNavigation}
        username={username}
        onOpenSearch={() => setSearchOpen(true)}
      />
      <NavigationMobile
        topNavigation={topNavigation}
        mainNavigation={mainNavigation}
        username={username}
        onOpenSearch={() => setSearchOpen(true)}
      />
      <SearchModal
        searchUrl={searchUrl}
        open={isSearchOpen}
        onCloseSearch={() => setSearchOpen(false)}
      />
    </header>
  )
}
