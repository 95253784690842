import { browserEnvs } from '../envs'
import type { Locale } from '../locale'
import { getDefaultLocale } from '../locale'
import { LOGGED_IN_ROOT_ROUTE, LOGGED_IN_ROOT_ROUTE_INTL } from '../routes'

/**
 * Add all private pages to array below to ensure that you are redirected
 * to mainpage when auth-session is invalid
 */
export const isPathPrivate = (path: string, currentLocale?: Locale) => {
  const country = browserEnvs.NEXT_PUBLIC_COUNTRY
  const locale = currentLocale ?? getDefaultLocale(country)
  const privateRoutes = {
    withLocale: `${LOGGED_IN_ROOT_ROUTE_INTL[locale]}/`,
    withoutLocale: `${LOGGED_IN_ROOT_ROUTE}/`,
  }
  return path.includes(privateRoutes.withLocale) || path.includes(privateRoutes.withoutLocale)
}
