import type { Country } from './envs'

export const oneTrustConfig = (country: Country): { domain: string } => {
  const defaultDomain = '018ef09a-0ca4-794a-9b92-ba97f04d8fad-test' // This is SE value
  switch (country) {
    case 'FI':
      return { domain: '01953d15-788d-7026-bc46-a2a3c6013e28-test' }
    case 'SE':
    case 'GB':
    case 'NO': // change to NO value once available
    default:
      return { domain: defaultDomain }
  }
}
