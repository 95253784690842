'use client'

import type { ReactNode } from 'react'

import { Box, spacing } from '@fortum/elemental-ui'

import type { IconId } from '@/shared/components/Icon'
import type { LinkListEntry } from '@/shared/contentful/types'
import {
  isTypeIconLink,
  isTypeImageLink,
  isTypeLink,
} from '@/shared/contentful/types/contentTypeGuards'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { setContentfulImageUrlProtocol } from '@/shared/utils/contentfulImageUrlProtocol'

import { FeatureItem } from '../../../FeatureItem'
import Heading from '../../../Heading/Heading'
import { LargeFeatureItem } from '../../../LargeFeatureItem'

export const FeatureColumn = (props: LinkListEntry) => {
  const { colors } = useTheme()

  const renderItems = (): ReactNode => {
    if (props.linksCollection?.items) {
      return props.linksCollection.items.map((link, index) => {
        if (link) {
          if (isTypeIconLink(link)) {
            return (
              <FeatureItem
                key={index}
                primaryText={link.label ?? ''}
                href={getLinkEntryUrl(link)}
                icon={link.icon as IconId}
                secondaryText={link.secondaryLabel ?? ''}
              />
            )
          }
          if (isTypeImageLink(link)) {
            return (
              <LargeFeatureItem
                key={index}
                primaryText={link.label ?? ''}
                imgUrl={setContentfulImageUrlProtocol(link.image?.image?.url)}
                href={getLinkEntryUrl(link)}
                secondaryText={link.secondaryLabel ?? ''}
              />
            )
          }
          if (isTypeLink(link)) {
            return (
              <FeatureItem
                key={index}
                primaryText={link.label ?? ''}
                href={getLinkEntryUrl(link)}
                secondaryText={link.secondaryLabel ?? ''}
              />
            )
          }
        }
      })
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing.xxs}
      pv={spacing.m}
      ph={{ default: spacing.xs, xl: spacing.xxs }}
      backgroundColor={colors.backgroundSecondary}
      flex="1"
      data-testid="main-nav-feature"
    >
      {props.titleLink && <Heading titleLink={props.titleLink} />}
      <Box display="flex" flexDirection="column" alignItems="stretch" gap={spacing.xxs}>
        {renderItems()}
      </Box>
    </Box>
  )
}
