import { signIn } from 'next-auth/react'

import { countryConfig } from '../countryConfig'
import { type Locale } from '../locale'
import { routes } from '../routes'

const appendQueryParams = (url: string | undefined) => {
  if (!url) {
    return
  }
  const [base, search] = url.split('?')
  const params = new URLSearchParams(search)
  params.set('gtmLog', 'true')
  params.set('getTerms', 'true')
  return `${base}?${params.toString()}`
}

export const signInHandler = async (providerId: string, locale: Locale, redirectPath?: string) => {
  const redirectUrl = redirectPath ? `${countryConfig.basePath}${redirectPath}` : undefined

  await signIn(
    providerId,
    {
      callbackUrl:
        appendQueryParams(redirectUrl) ??
        `${countryConfig.basePath}/${locale}${routes.LOGGED_IN_OVERVIEW}?gtmLog=true&getTerms=true`,
    },
    {
      ui_locales: locale,
    },
  )
}
