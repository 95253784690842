import FocusTrap from 'focus-trap-react'
import { useTranslations } from 'next-intl'
import type { FormEventHandler } from 'react'
import { useRef } from 'react'

import {
  Box,
  ButtonPill,
  Col,
  Grid,
  Hidden,
  IconButton,
  IconCross,
  Overlay,
  Portal,
  Row,
  Search,
  spacing,
  useEventTargetValue,
} from '@fortum/elemental-ui'

import { StyledHeading } from '@/shared/components/StyledHeading'
import { useTheme } from '@/shared/hooks/useTheme'

const SEARCH_PARAM = 'q'

export type SearchModalProps = {
  searchUrl: string
  open: boolean
  onCloseSearch: VoidFunction
}

export const SearchModal = ({ searchUrl, open, onCloseSearch }: SearchModalProps) => {
  const { colors } = useTheme()
  const t = useTranslations('searchModal')
  const inputRef = useRef<HTMLInputElement>(null)
  const [query, handleQueryChange] = useEventTargetValue()

  /**
   * Removes any soft-hyphen characters (\u00AD) or literal `&shy;` strings
   * from a string (often copy-pasted from HTML).
   */
  const checkAndRemoveSoftHyphens = (value: string = '') => {
    return value.replaceAll(/(\u00AD|&shy;)/g, '')
  }

  // Prevent search with empty query
  const handleSubmit: FormEventHandler = (event) => {
    if (!query) {
      event.preventDefault()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedValue = checkAndRemoveSoftHyphens(e.target.value)
    handleQueryChange({ target: { value: cleanedValue } })
  }

  return (
    <Portal>
      <FocusTrap active={open} focusTrapOptions={{ initialFocus: () => inputRef.current }}>
        <search>
          <form action={searchUrl} onSubmit={handleSubmit} data-testid="search-form">
            <Overlay opened={open} onClose={onCloseSearch}>
              <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                background={colors.backgroundSecondary}
                pv={{ default: spacing.xs, xl: spacing.m }}
              >
                <Grid>
                  <Row justifyContent="center">
                    <Col xl={8}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={spacing.xs}
                        mb={spacing.xs}
                      >
                        <StyledHeading level={5} color={colors.brandPrimary}>
                          {t('title')}
                        </StyledHeading>
                        <Hidden below="m">
                          <ButtonPill
                            status="plain"
                            variant="condensed"
                            ml={spacing.xs}
                            mr={`-${spacing.xs}`}
                            rightIcon={<IconCross />}
                            onClick={onCloseSearch}
                          >
                            {t('closeButton')}
                          </ButtonPill>
                        </Hidden>
                        <Hidden above="m">
                          <IconButton
                            status="plain"
                            icon={<IconCross />}
                            mr={`-${spacing.xxxs}`}
                            aria-label={t('closeButton')}
                            onClick={onCloseSearch}
                          ></IconButton>
                        </Hidden>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection={{ default: 'column', s: 'row' }}
                        alignItems={{ default: 'stretch', s: 'center' }}
                        gap={spacing.xs}
                      >
                        <Search
                          name={SEARCH_PARAM}
                          label={t('inputLabel')}
                          rightIconAriaLabel={t('clearInputLabel')}
                          inputRef={inputRef}
                          value={query}
                          onChange={handleInputChange}
                        />
                        <ButtonPill type="submit" status="primary" flexShrink={0}>
                          {t('searchButton')}
                        </ButtonPill>
                      </Box>
                    </Col>
                  </Row>
                </Grid>
              </Box>
            </Overlay>
          </form>
        </search>
      </FocusTrap>
    </Portal>
  )
}
