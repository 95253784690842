'use client'

import { useTranslations } from 'next-intl'
import { type FC } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { Box, ButtonPill, ContentText, IconButton, IconCross, spacing } from '@fortum/elemental-ui'

import { Link } from '@/i18n/navigation'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

import type { AnnouncementContainerProps } from './AnnouncementContainer'
import { Icon } from '../Icon'
import { Section } from '../Section'

export type AnnouncementProps = Omit<AnnouncementContainerProps, 'sys'> & {
  /**
   * Function to close the announcement
   */
  handleClose: () => void
  /**
   * If it is opened or not
   */
  isOpened: boolean
  /**
   * Slide down animation
   */
  shouldSlideDown: boolean
}
/**
 * Announcement component is responsible for rendering a Announcement with a message and a link.
 */
export const Announcement: FC<AnnouncementProps> = ({
  content,
  link,
  title,
  handleClose,
  isOpened,
  shouldSlideDown,
}) => {
  const t = useTranslations('announcement')

  const { colors } = useTheme()

  return (
    <AnnouncementSection
      position="relative"
      $isClosing={!isOpened}
      $shouldSlideDown={shouldSlideDown}
      paddingVertical={spacing.xxs}
      ph={{ default: spacing.m, s: spacing.l, m: 'auto' }}
      borderBottom={{ default: `1px solid ${colors.borderPrimary}`, xl: 'none' }}
      data-testid="announcement"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={spacing.xxxs}
        justifyContent="center"
        alignItems="center"
      >
        <Box gap={spacing.xxxxs} display="flex" flexDirection="column">
          <ContentText size="l" textAlign="center" color={colors.textPositive} data-testid="title">
            {title}
          </ContentText>
          <ContentText
            size="s"
            textAlign="center"
            color={colors.textSecondary}
            data-testid="content"
          >
            {content}
          </ContentText>
        </Box>
        {link && (
          <StyledButtonPill
            tag={Link}
            href={getLinkEntryUrl(link)}
            variant="condensed"
            size="s"
            background={colors.backgroundPrimary}
            data-testid="marketing-link"
          >
            <StyledText size="s">{link.label}</StyledText>
          </StyledButtonPill>
        )}
      </Box>
      <Box position="absolute" right={spacing.xxxs} top={spacing.xxxs}>
        <IconButton
          cursor="pointer"
          aria-label={t('closeAnnouncement')}
          color={colors.backgroundPrimary}
          data-testid="close-announcement-button"
          icon={<Icon icon={IconCross} />}
          onClick={handleClose}
        />
      </Box>
    </AnnouncementSection>
  )
}

const slideUp = keyframes`
  0% {
    transform: translateY(0);
    height: auto;
    padding: initial;
  }
  100% {
    transform: translateY(-200%);
    height: 0;
    padding: 0;
  }
`

const slideDown = keyframes`
  0% {
    transform: translateY(-200%);
    height: 0;
    padding: 0;
  }
  100% {
    transform: translateY(0);
    height: auto;
    padding: 'initial';
  }
`

const AnnouncementSection = styled(Section)<{ $isClosing: boolean; $shouldSlideDown: boolean }>`
  transition:
    transform 0.5s ease-in-out,
    height 0.5s ease-in-out,
    padding 0.5s ease-in-out;
  overflow: hidden;

  ${({ $isClosing, $shouldSlideDown }) =>
    !$isClosing &&
    $shouldSlideDown &&
    css`
      animation: ${slideDown} 0.5s forwards;
    `}

  ${({ $isClosing }) =>
    $isClosing &&
    css`
      animation: ${slideUp} 0.5s forwards;
    `}
`
const StyledText = styled(ContentText)`
  ${({ theme }) => css`
    color: ${theme.colors.brandPrimary};
  `}
`

const StyledButtonPill = styled(ButtonPill)`
  &:hover,
  &:focus,
  &:active {
    ${StyledText} {
      color: ${({ theme }) => theme.colors.textInverted};
    }
  }
`
