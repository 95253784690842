import type { RumInitConfiguration } from '@datadog/browser-rum'
import deepmerge from 'deepmerge'
import type { DeepPartial } from 'react-hook-form'
import { match } from 'ts-pattern'

import type { Tenant } from '@fortum/global-consent-components'

import { countryConfig } from '@/shared/countryConfig'
import type { CbusRequestingSystem } from '@/shared/services/clients/cbusClient'

import type { AppEnv, Country } from './envs'
import { serverEnvs } from './envs'
import { getGTMConfig } from './gtmConfig'
import type { NextCacheConfig } from './nextCacheConfig'
import { nextCacheConfig } from './nextCacheConfig'
import { oneTrustConfig } from './oneTrustConfig'

export type AppConfig = {
  appEnv: AppEnv
  commonBackend: {
    graphqlUrl: string
  }
  ciam: {
    clientId: string
    providerId: string
    configurationUrl: string
    signOutUrl: string
    resetPassword: string
    acrValue: string
  }
  contentful: {
    spaceId: string
    environment: string
    sharedSpaceId: string
    sharedEnvironment: string
  }
  gtm: {
    id?: string
    auth: string
    preview: string
  }
  onetrust: {
    domain: string
  }
  /**
   * Config for SoS API calls
   */
  cdp: {
    url: string
    requestingSystem: string
    /**
     * Special key shared by CDP SOS to bypass BankId step in ordering. Should be configured only for DEV and STG.
     */
    orderAuthenticationBypassToken: string
  }
  /**
   * Config for CBUS endpoints
   */
  cbus: Record<
    CbusRequestingSystem,
    {
      url: string
      requestingSystem: string
    }
  >
  /**
   * Config for enterprise app redirects
   */
  enterpriseApp: {
    url: string
  }
  /**
   * Config for legacy app redirects
   */
  legacyApp: {
    url: string
  }
  creditCardClient: {
    url: string
    apiKey: string
  }
  bankId: {
    url: string
    fetchTokenUrl: string
    clientId: string
    acrValue: string
  }
  showTestPages: boolean
  consent: {
    tenant: Tenant
    openWeb: {
      templateId: string
    }
    loggedIn: {
      templateId: string
      channel: string
    }
  }
  basicAuth: BasicAuth | false
  datadogRum: {
    clientToken: string
    applicationId: string
    site: RumInitConfiguration['site']
    service: string
    ddVersion: string
  }
  search: {
    url: string
  }
  nextCache: NextCacheConfig
}

type BasicAuth = {
  username: string
  password: string
  realm: string
}

// TODO: Debug and ensure that app config is server-only
/**
 * Get configuration by app environment and country.
 * This configuration is only available on the server.
 * Do not include any hardcoded secrets here.
 */
export const getAppConfig = (
  appEnv: AppEnv,
  _country: Country,
  serverSide: boolean = true,
): AppConfig => {
  if (!process.env.STORYBOOK && typeof window !== 'undefined' && serverSide) {
    throw new TypeError('Unsafely accessing app configs client side!')
  }

  const devConfig: AppConfig = {
    appEnv,
    commonBackend: {
      // NOTE: We use staging Apollo in dev because dev Apollo data is very limited.
      // See below for instructions on how to test dev Apollo locally.
      graphqlUrl: 'https://stgcosapi.fortum.com/gl/all/apollo/graphql',
    },
    ciam: {
      clientId: countryConfig.ciam.clientIdStg, //we use staging Forgerock in dev
      providerId: 'ciamtest',
      configurationUrl:
        'https://sso-staging.fortum.com/am/oauth2/alpha/.well-known/openid-configuration',
      signOutUrl: 'https://sso-staging.fortum.com/am/oauth2/connect/endSession',
      resetPassword:
        'https://sso-staging.fortum.com/am/XUI/?realm=/alpha&authIndexType=service&authIndexValue=ResetPassword',
      acrValue: countryConfig.ciam.acrValue,
    },
    contentful: {
      spaceId: 'bs7i8p83z7um',
      sharedSpaceId: '3osoplch9jmc',
      environment: 'test',
      sharedEnvironment: 'test',
    },
    gtm: getGTMConfig('dev', _country),
    cdp: {
      url: 'https://devcosapi.fortum.com',
      requestingSystem: 'OPEN_WEB',
      orderAuthenticationBypassToken: 'D2rredGX3x3oJC2r6YV2',
    },
    cbus: {
      openWeb: {
        /**
         * NOTE: This supposed to be DEV because of that CDP SOS has not staging environment. To avoid confusion we have to use dev as-well.
         * Otherwise in future different consents might be configured in STG and DEV. What might be misleading when testing it E2E.
         */
        url: 'https://testapi.fortum.com',
        requestingSystem: 'OPEN_WEB',
      },
      loggedIn: {
        // * NOTE: This supposed to be DEV but most likely stale/missing data on dev cbus environment.
        url: 'https://qaapi.fortum.com',
        requestingSystem: 'LOGGED_IN',
      },
    },
    legacyApp: {
      url: countryConfig.legacyApp.stagingUrl,
    },
    enterpriseApp: {
      url: countryConfig.enterpriseApp.stagingUrl,
    },
    creditCardClient: {
      url: 'https://sandbox1.ediex.no/inbound-http/api',
      apiKey: serverEnvs.CREDIT_CARD_CLIENT_API_KEY,
    },
    bankId: {
      url: 'https://sso2-staging.fortum.com/am/oauth2/realms/root/realms/bravo/authorize',
      fetchTokenUrl:
        'https://sso2-staging.fortum.com/am/oauth2/realms/root/realms/bravo/access_token',
      clientId: countryConfig.ciam.clientIdStg,
      acrValue: countryConfig.ciam.acrValuePassthrough,
    },
    showTestPages: true,
    consent: {
      tenant: countryConfig.consent.tenant,
      openWeb: {
        templateId: countryConfig.consent.openWebTemplateId.dev,
      },
      loggedIn: {
        templateId: countryConfig.consent.loggedInTemplateId.stg,
        channel: 'LISA',
      },
    },
    basicAuth: {
      username: process.env.BASIC_AUTH_USERNAME || '',
      password: process.env.BASIC_AUTH_PASSWORD || '',
      realm: 'Restricted',
    },
    datadogRum: {
      applicationId: '2d8a99b8-0ea1-498a-b7a8-338d717d9632',
      clientToken: 'puba50c0762a84a3d38d234ec83c9871d36',
      site: 'datadoghq.eu',
      service: 'global-web-' + _country.toLocaleLowerCase(),
      ddVersion: process.env.DD_VERSION || '',
    },
    search: {
      url: 'https://dev.fortum.com/se/search',
    },
    onetrust: oneTrustConfig(_country),
    nextCache: nextCacheConfig,
  }

  const localConfig = deepmerge<AppConfig, DeepPartial<AppConfig>>(devConfig, {
    commonBackend: {
      // Uncomment this to use dev Apollo
      // Also update ciam section to use dev ForgeRock
      //graphqlUrl: 'https://devcosapi.fortum.com/gl/all/apollo/graphql',
    },
    ciam: {
      // Uncomment these to use dev ForgeRock
      // clientId: 'globalwebdev',
      // providerId: 'ciamtest',
      // configurationUrl:
      //   'https://sso-dev.fortum.com/am/oauth2/alpha/.well-known/openid-configuration',
      // signOutUrl: 'https://sso-dev.fortum.com/am/oauth2/connect/endSession',
      // Uncomment to test with short session time-to-life
      // clientId: 'globalwebstagingshort',
    },
    contentful: {
      environment: serverEnvs.CONTENTFUL_ENVIRONMENT ?? 'test',
      sharedEnvironment: serverEnvs.CONTENTFUL_SHARED_ENVIRONMENT ?? 'test',
    },
    basicAuth: false,
    nextCache: {
      ...nextCacheConfig,
      //Overwrite here any next cache tag config
    },
  })

  const stgConfig = deepmerge<AppConfig, DeepPartial<AppConfig>>(devConfig, {
    contentful: {
      environment: 'master',
      sharedEnvironment: 'master',
    },
    showTestPages: false,
    gtm: getGTMConfig('stg', _country),
    datadogRum: {
      applicationId: 'd9575b6d-0e32-420c-a8c2-d3cbf98f12e4',
      clientToken: 'pub5630b7bb93ba4531b0a23e2039ec0a1f',
    },
    search: {
      url: 'https://staging.fortum.com/se/search',
    },
    legacyApp: {
      url: countryConfig.legacyApp.stagingUrl,
    },
  })

  // TODO: Update values for production
  const prdConfig = deepmerge<AppConfig, DeepPartial<AppConfig>>(stgConfig, {
    bankId: {
      url: 'https://sso2.fortum.com/am/oauth2/realms/root/realms/bravo/authorize',
      fetchTokenUrl: 'https://sso2.fortum.com/am/oauth2/realms/root/realms/bravo/access_token',
      clientId: countryConfig.ciam.clientIdPrd,
    },
    cdp: {
      url: 'https://cosapi.fortum.com',
      orderAuthenticationBypassToken: 'NEVER PASS TOKEN HERE',
    },
    ciam: {
      clientId: countryConfig.ciam.clientIdPrd,
      providerId: 'ciamprod',
      configurationUrl: 'https://sso.fortum.com/am/oauth2/alpha/.well-known/openid-configuration',
      signOutUrl: 'https://sso.fortum.com/am/oauth2/connect/endSession',
      resetPassword:
        'https://sso.fortum.com/am/XUI/?realm=/alpha&authIndexType=service&authIndexValue=ResetPassword',
    },
    commonBackend: {
      graphqlUrl: 'https://cosapi.fortum.com/gl/all/apollo/graphql',
    },
    cbus: {
      openWeb: {
        url: 'https://api.fortum.com',
        requestingSystem: 'OPEN_WEB',
      },
      loggedIn: {
        url: 'https://api.fortum.com',
        requestingSystem: 'LOGGED_IN',
      },
    },
    enterpriseApp: {
      url: countryConfig.enterpriseApp.prodUrl,
    },
    legacyApp: {
      url: countryConfig.legacyApp.prodUrl,
    },
    creditCardClient: {
      url: 'https://edi.edigard.com/inbound-http/api',
    },
    basicAuth: false, // Uncomment before go-live
    consent: {
      openWeb: {
        templateId: countryConfig.consent.openWebTemplateId.prd,
      },
      loggedIn: {
        // templateId: '664edb49c562c307bb09a370', -> Actual templateId for production
        templateId: countryConfig.consent.loggedInTemplateId.prd,
      },
    },
    datadogRum: {
      applicationId: 'f43f29be-2a52-41cc-ab92-c0f34dd53eb7',
      clientToken: 'pub9d4e5df6b7be8dcf829454216abe3f47',
    },
    search: {
      url: 'https://www.fortum.com/se/search',
    },
  })

  return match(appEnv)
    .returnType<AppConfig>()
    .with('local', () => localConfig)
    .with('dev', () => devConfig)
    .with('stg', () => stgConfig)
    .with('prd', () => prdConfig)
    .otherwise(() => localConfig) // Tests
}
