import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'

import {
  Box,
  FortumLogo,
  Hidden,
  IconButton,
  IconMenu,
  IconSearch,
  spacing,
} from '@fortum/elemental-ui'

import { Link } from '@/i18n/navigation'
import { Icon } from '@/shared/components/Icon'
import type { LinkEntry, MainNavigationEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { getMediaQuery, useMediaQuery } from '@/shared/hooks/useBreakpoints'
import { useTheme } from '@/shared/hooks/useTheme'
import { useFeatureFlag } from '@/shared/providers/featureFlag'

import { SliderMenu } from './SliderMenu'
import { EnterpriseLoginButton } from '../parts/Buttons/EnterpriseLoginButton'
import { LoginButton } from '../parts/Buttons/LoginButton'
import { UserBadge } from '../parts/Buttons/UserBadge'
import { useCloseOnPathnameChange } from '../parts/hooks/useCloseOnPathnameChange'
import { ShadowOverlay } from '../parts/ShadowOverlay'

export type NavigationMobileProps = {
  /**
   * Top navigation links
   */
  topNavigation: LinkEntry[]
  /**
   * Main navigation items
   */
  mainNavigation: MainNavigationEntry
  /**
   * Username of the logged in user
   */
  username: string | undefined
  /**
   * Open search callback
   */
  onOpenSearch: VoidFunction
}
/**
 * Component responsible for mobile navigation
 */
export const NavigationMobile = ({
  topNavigation,
  mainNavigation,
  username,
  onOpenSearch,
}: NavigationMobileProps) => {
  const theme = useTheme()

  const t = useTranslations('mainNavigation')
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [navigationZIndex, setNavigationZIndex] = useState(90)

  const logoLink = mainNavigation?.logoLink
  const customerType = mainNavigation?.customerType

  const isXl = useMediaQuery(getMediaQuery('xl') || getMediaQuery('xxl'))

  const isEnabled = useFeatureFlag('enableNavigationSearchFields')

  const handleMenuLinkClick = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  const handleCloseMenuLinkClick = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  useCloseOnPathnameChange(() => setIsMenuOpened(false))

  useEffect(() => {
    if (isXl) {
      setIsMenuOpened(false)
    }
  }, [isXl])

  const animationDuration = 300

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined

    if (isMenuOpened) {
      setNavigationZIndex(100)
    } else {
      setNavigationZIndex(90)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isMenuOpened, animationDuration])

  return (
    <>
      <Hidden above="xl" zIndex={navigationZIndex} position="sticky" top={0}>
        <ShadowOverlay
          $isOpen={isMenuOpened}
          onClick={handleCloseMenuLinkClick}
          $duration={animationDuration}
          data-testid="mobile-shadow-overlay"
        />
        <Box
          zIndex={90}
          position="sticky"
          top={0}
          backgroundColor={theme.colors.backgroundPrimary}
          borderBottom={`1px solid ${theme.colors.borderPrimary}`}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IconButton
              status="plain"
              data-testid="mobile-open-menu-button"
              aria-label={t('openMenu')}
              height={spacing.l}
              width={spacing.l}
              color={theme.colors.textPrimary}
              icon={<Icon icon={IconMenu} />}
              onClick={handleMenuLinkClick}
            />

            <Link
              data-test-id="mobile-fortum-logo-link"
              href={(logoLink && getLinkEntryUrl(logoLink)) ?? '/'}
            >
              <FortumLogo height={spacing.m} role="link" size="s" color={theme.colors.oceanGreen} />
            </Link>

            <Box display="flex" alignItems="center">
              <IconButton
                status="plain"
                data-testid="mobile-search-button"
                aria-label={t('search')}
                height={spacing.l}
                width={spacing.l}
                color={theme.colors.textPrimary}
                icon={<Icon icon={IconSearch} />}
                onClick={onOpenSearch}
                display={isEnabled ? 'block' : 'none'}
              />

              {customerType === 'Enterprise' ? (
                <EnterpriseLoginButton
                  data-testid="mobile-enterprise-login-button"
                  color={theme.colors.text}
                  height={spacing.l}
                  padding={spacing.xxs}
                  loginUrl={mainNavigation.b2BLoginUrl ?? undefined}
                />
              ) : username ? (
                <UserBadge
                  data-testid="mobile-user-badge"
                  name={username}
                  padding={spacing.xxxs}
                  setNavigationZIndex={setNavigationZIndex}
                  megaMenuIsOpen={isMenuOpened}
                />
              ) : (
                <LoginButton
                  data-testid="mobile-login-button"
                  color={theme.colors.text}
                  height={spacing.l}
                  padding={spacing.xxs}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Hidden>
      <SliderMenu
        topNavigation={topNavigation}
        mainNavigation={mainNavigation}
        isMenuOpened={isMenuOpened}
        handleCloseMenuLinkClick={handleCloseMenuLinkClick}
      />
    </>
  )
}
