import { useTranslations } from 'next-intl'
import styled from 'styled-components'

// eslint-disable-next-line no-restricted-imports
import { Link } from '@fortum/elemental-ui'

export const SkipToMainContent = () => {
  const t = useTranslations()

  return (
    <SkipLink href="#main-content" noUnderline data-testid="skip-to-main-content">
      {t('skipToMainContent')}
    </SkipLink>
  )
}

const SkipLink = styled(Link)`
  position: absolute !important;
  opacity: 0;
  overflow: hidden;
  word-wrap: normal;

  &:focus {
    position: static !important;
    opacity: 1;
    overflow: visible;
  }
`
