import { httpBatchLink } from '@trpc/client'
import superjson from 'superjson'

import { countryConfig } from '@/shared/countryConfig'
import { trpc } from '@/shared/trpc/react'

/**
 * This locale should be available from the path.
 * Optionally, URL can be provided as a prop.
 *
 * IMPORTANT: IT SHOULD NOT STAY AS DEFAULT FOREVER!
 */
const url = `${countryConfig.basePath}/api/trpc/`

export const getTrpcClient = () =>
  trpc.createClient({
    links: [
      httpBatchLink({
        url,
      }),
    ],
    transformer: superjson,
  })
