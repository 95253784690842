'use client'

import { useTranslations } from 'next-intl'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import { Button, IconProfileOutline, generateColorLuminances, spacing } from '@fortum/elemental-ui'

import { usePathname, useRouter } from '@/i18n/navigation'
import { browserEnvs } from '@/shared/envs'
import { useLocale } from '@/shared/locale'
import { useClientSideConfs } from '@/shared/providers/appEnv'
import { routes, serviceBreakClientSearchParam } from '@/shared/routes'
import { vanillaTRPCClient } from '@/shared/trpc/react'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'
import { stepEvent } from '@/shared/utils/sendGTMEvent'
import { signInHandler } from '@/shared/utils/signInHandler'
import { fetchDataWithTimeout } from '@/shared/utils/trpc/fetchDataWithTimeout'

export type LoginButtonProps = {
  label?: string
  color?: string
  padding?: string
  height?: string
}

export const LoginButton = ({ label, color, padding, height }: LoginButtonProps) => {
  const t = useTranslations('mainNavigation')
  const router = useRouter()
  const pathname = usePathname()
  const locale = useLocale()
  const clientSideConfs = useClientSideConfs()
  const [isLoading, setIsLoading] = useState(false)

  const fetchIsServiceBreak = async () => {
    try {
      return await fetchDataWithTimeout({
        timeout: 5000,
        trpcResolver: vanillaTRPCClient.loggedIn.serviceBreak.isLoggedInServiceBreakEnabled.query,
      })
    } catch (error) {
      logError(new UiError(error, 'LoginButton'))
      return false
    }
  }

  const onClickHandler = async () => {
    setIsLoading(true)
    const isServiceBreak = await fetchIsServiceBreak()
    if (isServiceBreak) {
      router.push({
        pathname: routes.SERVICE_BREAK,
        params: { client: serviceBreakClientSearchParam.LOG_IN },
      })
    } else {
      await signInHandler(clientSideConfs.ciam.providerId, locale)
      stepEvent('click_login_CTA', { site_brand: `fortum ${browserEnvs.NEXT_PUBLIC_COUNTRY}` })
    }
    setIsLoading(false)
  }

  return (
    pathname !== routes.SERVICE_BREAK && (
      <StyledButton
        status="plain"
        leftIcon={IconProfileOutline}
        onClick={onClickHandler}
        color={color}
        aria-label={t('login')}
        loading={isLoading}
        fontSize={spacing.xxs}
        ph={padding}
        height={height}
        data-testid="loginButton"
      >
        {label}
      </StyledButton>
    )
  )
}

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    &:hover {
      background: none;
      color: ${generateColorLuminances('hover', theme).bg};
    }

    &:active {
      background: none;
      color: ${generateColorLuminances('click', theme).bg};
    }
  `}
`
