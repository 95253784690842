import { z } from 'zod'

import { getLocalStorageValue } from './localStorage'

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[]
}

type GTMItemType = {
  [key: string]: string | number | undefined | null
}

type GTMEventType = {
  [key: string]: string | number | undefined | null | { items: GTMItemType[] }
}

declare const window: WindowWithDataLayer

export const GTM_ID = process.env.NEXT_PUBLIC_GTM

export const sendGTMEvent = (data: GTMEventType) => {
  if (window.dataLayer !== undefined) {
    const customerId = getLocalStorageValue('customerId', z.string(), '')

    if (customerId) {
      window.dataLayer.push({
        ...data,
        customer_id: customerId,
      })
    } else {
      window.dataLayer.push(data)
    }
  }
}

export const contractItemsEvent = (
  event: string,
  ecommerceData: GTMEventType,
  data: GTMItemType[],
) => {
  sendGTMEvent({ ecommerce: null })

  const gtmData = {
    event,
    ecommerce: {
      ...ecommerceData,
      items: data,
    },
  }
  sendGTMEvent(gtmData)
}

export const stepEvent = (event: string, data: GTMItemType) => {
  sendGTMEvent({ ecommerce: null })
  sendGTMEvent({
    event,
    ...data,
  })
}
