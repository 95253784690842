// eslint-disable-next-line no-restricted-imports
import { usePathname as useNextPathname } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'

import {
  Box,
  Button,
  ContentText,
  Grid,
  IconSearch,
  generateColorLuminances,
  spacing,
} from '@fortum/elemental-ui'

import { isExternal, normalizeUrl, usePathname, useRouter } from '@/i18n/navigation'
import { isStaticRoute } from '@/i18n/utils'
import { Icon } from '@/shared/components/Icon'
import type { LinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { browserEnvs } from '@/shared/envs'
import { useTheme } from '@/shared/hooks/useTheme'
import { type Locale, getPathPrefix, useLocale } from '@/shared/locale'
import { useFeatureFlag } from '@/shared/providers/featureFlag'
import { routes } from '@/shared/routes'

import { LanguageMenu } from '../../parts/LanguageMenu'
import { TopNavigationLink } from '../../parts/Links/TopNavigationLink'

type TopNavigationProps = {
  /**
   * Links to be displayed in the top navigation
   */
  topNavigation: LinkEntry[]
  /**
   * Open search callback
   */
  onOpenSearch: VoidFunction
}

/**
 * Top navigation menu and links for desktop
 */
export const TopNavigation = ({ topNavigation, onOpenSearch }: TopNavigationProps) => {
  const router = useRouter()
  const locale = useLocale()
  const localizedPathname = useNextPathname()
  const pathname = usePathname()
  const t = useTranslations('mainNavigation')
  const theme = useTheme()
  const isEnabled = useFeatureFlag('enableNavigationSearchFields')

  const selectedTopBarItem = useMemo(() => {
    const pathPrefix = getPathPrefix(browserEnvs.NEXT_PUBLIC_COUNTRY, locale)
    if (!topNavigation) {
      return
    }

    const matchingItem = topNavigation.find((item) => {
      if (item) {
        const itemUrl = normalizeUrl(getLinkEntryUrl(item).toLowerCase())
        const normalizedPathname = normalizeUrl(localizedPathname.toLowerCase())

        if (isExternal(itemUrl)) {
          return false
        }

        const itemUrlWithPrefix = normalizeUrl(`${pathPrefix}${itemUrl}`)

        return normalizedPathname === itemUrlWithPrefix
      }
      return false
    })

    return matchingItem ? matchingItem.name : topNavigation[0]?.name
  }, [topNavigation, localizedPathname])

  const handleLanguageChange = (locale: Locale) => {
    if (isStaticRoute(pathname)) {
      router.push(pathname, { locale })
      return
    }

    if (pathname.startsWith(routes.ENTERPRISE_HOME)) {
      router.push(routes.ENTERPRISE_HOME)
      return
    }

    //TODO: Think how in a smart way we can handle dynamic paths language change. Initial idea is to use session storage and put there {params} for all locales.
    router.push(routes.HOME, { locale })
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      position="relative"
      zIndex={500}
      backgroundColor={theme.colors.backgroundLightOnDark}
    >
      <Grid display="flex" justifyContent="space-between">
        <Box display="flex">
          {topNavigation &&
            topNavigation.map(
              (topBarItem) =>
                topBarItem && (
                  <TopNavigationLink
                    dataTestId={`top-nav-item-${topBarItem.label}`}
                    key={topBarItem.name}
                    label={topBarItem.label ?? ''}
                    href={getLinkEntryUrl(topBarItem)}
                    isActive={selectedTopBarItem === topBarItem.name}
                  />
                ),
            )}
        </Box>
        <Box display="flex">
          <EffectButton
            data-testid="top-search-button"
            status="plain"
            p={0}
            height={spacing.m}
            border="none"
            borderRadius={0}
            onClick={onOpenSearch}
            display={isEnabled ? 'block' : 'none'}
          >
            <Box
              display="flex"
              height={spacing.m}
              gap={spacing.xxxs}
              alignItems="center"
              ph={spacing.xxs}
            >
              <Icon icon={IconSearch} size={24} color={theme.colors.textLightOnDark} />
              <ContentText color={theme.colors.textLightOnDark} size="xs">
                {t('search')}
              </ContentText>
            </Box>
          </EffectButton>

          <LanguageMenu onSelect={handleLanguageChange} />
        </Box>
      </Grid>
    </Box>
  )
}

const EffectButton = styled(Button)<{ $isActive?: boolean }>`
  ${({ theme, $isActive }) => css`
    ${$isActive &&
    css`
      &:hover,
      &:focus,
      &:active {
        background-color: ${generateColorLuminances('click', theme).bg};
      }
    `}
    &:hover,
    &:focus {
      background-color: ${generateColorLuminances('click', theme).bg};
    }

    &:active {
      background-color: ${generateColorLuminances('normal', theme, { color: '#071210' }).bg};
    }
  `}
`
